@import './styles/_mixins.scss';
@import '~bootstrap/scss/bootstrap.scss';

body {
  background: rgba(0, 0, 0, 0.1);
}

.white-space-pre-line {
  white-space: pre-line;
}
