@import './styles/_mixins.scss';

.App {
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    width: 576px;
  }

  @include media-breakpoint-up(md) {
    width: 700px;
  }
}

label:not(.custom-file-label) {
  top: 8px;
}

input:not(:disabled):not(.custom-file-input) {
  z-index: 1;
  background: transparent !important;
}

input:not(.custom-file-input) + label:not(.custom-file-label) {
  left: auto;
  right: 12px;
}

input:not(.custom-file-input)[value=""] + label:not(.custom-file-label) {
  left: 12px;
}


input#date + label {
  right: 12px !important;
  left: auto !important;
}

input#date:hover + label, input#date:active + label, input#date:focus + label {
  right: 50px !important;
}
